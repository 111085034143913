// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {

  apiKey: "AIzaSyC5uxMCZSgPZAygc60QQ7v2FE1bHk2qQMk",

  authDomain: "billolpo.firebaseapp.com",

  databaseURL: "https://billolpo-default-rtdb.firebaseio.com",

  projectId: "billolpo",

  storageBucket: "billolpo.appspot.com",

  messagingSenderId: "308851761777",

  appId: "1:308851761777:web:709e3e8d45d16bd873f1d0",

  measurementId: "G-J7WGRMHHB8"

};





// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {app}